import { useEffect, useState } from "react";

function Diaporama({ photos, selectedPhoto, onClose }) {
  const [openImage, setOpenImage] = useState(null);
  const [isPlayed, setIsPlayed] = useState(false);

  useEffect(() => {
    setOpenImage(selectedPhoto);

    let interval = setInterval(() => {
      isPlayed &&
        setOpenImage((image) => {
          if (photos.indexOf(image) === photos.length - 1) {
            return photos[0];
          }
          return photos[photos.indexOf(image) + 1];
        });
    }, 5000);

    return () => {
      interval = null;
    };
  }, [selectedPhoto, setOpenImage, isPlayed, photos, setIsPlayed]);

  return (
    <>
      {openImage && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FFF",
            width: "100%",
            height: "100%",
            padding: "2rem",
            zIndex: 5,
          }}
        >
          <img
            style={{
              maxWidth: 768,
              maxHeight: "100%",
              objectFit: "cover",
              width: "auto",
            }}
            src={openImage}
            alt={openImage}
          />
          <button
            style={{
              padding: "0.5rem 0.75rem",
              textTransform: "uppercase",
              fontSize: "0.75rem",
              fontWeight: 500,
              letterSpacing: "0.25rem",
              position: "fixed",
              top: 100,
              left: 0,
              border: "1px solid rgba(0, 0, 0, 0.08)",
              backgroundColor: "#000",
              color: "#FFF",
              zIndex: 2,
            }}
            onClick={() => setIsPlayed(!isPlayed)}
          >
            {isPlayed ? "Stop" : "Play"}
          </button>
          <button
            style={{
              padding: "0.5rem 0.75rem",
              textTransform: "uppercase",
              fontSize: "0.75rem",
              fontWeight: 500,
              letterSpacing: "0.25rem",
              position: "fixed",
              top: 100,
              right: 0,
              border: "1px solid rgba(0, 0, 0, 0.08)",
              backgroundColor: "#000",
              color: "#FFF",
              zIndex: 2,
            }}
            onClick={() => onClose()}
          >
            Close
          </button>
          {photos.indexOf(openImage) > 0 && (
            <button
              style={{
                padding: "0.5rem 0.75rem",
                textTransform: "uppercase",
                fontSize: "1rem",
                fontWeight: 500,
                letterSpacing: "0.25rem",
                position: "fixed",
                top: "calc(50% - 36px)",
                left: 0,
                border: "1px solid rgba(0, 0, 0, 0.08)",
                backgroundColor: "#000",
                color: "#FFF",
                zIndex: 5,
              }}
              onClick={() =>
                setOpenImage(photos[photos.indexOf(openImage) - 1])
              }
            >
              &larr;
            </button>
          )}
          {photos.indexOf(openImage) < photos.length - 1 && (
            <button
              style={{
                padding: "0.5rem 0.75rem",
                textTransform: "uppercase",
                fontSize: "1rem",
                fontWeight: 500,
                letterSpacing: "0.25rem",
                position: "fixed",
                top: "calc(50% - 36px)",
                right: 0,
                border: "1px solid rgba(0, 0, 0, 0.08)",
                backgroundColor: "#000",
                color: "#FFF",
                zIndex: 5,
              }}
              onClick={() =>
                setOpenImage(photos[photos.indexOf(openImage) + 1])
              }
            >
              &rarr;
            </button>
          )}
        </div>
      )}
    </>
  );
}

export default Diaporama;
