function About() {
  return (
    <div style={{ maxWidth: 768 }}>
      <div
        style={{
          fontSize: "2rem",
          margin: "3rem 0",
          lineHeight: "2.5rem",
          textAlign: "center",
          fontStyle: "italic",
        }}
      >
        “Travel makes one modest. You see what a tiny place you occupy in the
        world.”
        <div style={{ fontStyle: "normal", fontSize: "1.75rem" }}>
          Gustave Flaubert
        </div>
      </div>

      <img
        alt=""
        src="https://raw.githubusercontent.com/rina-andria/rwt/master/about.jpg"
        style={{ width: "100%" }}
      />

      <ul style={{ margin: "2rem 0" }}>
        {[
          "We have a deep appreciation for nature.",
          "We enjoy exploring new things.",
          "We savor delicious food.",
          "We appreciate a good beer.",
          "We delight in meeting new people.",
          "We have a soft spot for animals.",
          "We groove to the beat of music.",
          "We thrive on adventurous experiences.",
          "We're open to surprises, whether they're good or bad.",
          "So, on our world trip, we're hoping to experience all of these things!",
        ].map((li) => (
          <li key={li} style={{ padding: "0.25rem" }}>
            {li}
          </li>
        ))}
      </ul>

      <p style={{ marginBottom: "2rem" }}>Zita & Rina - Travelers</p>
    </div>
  );
}

export default About;
