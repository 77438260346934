import { useState } from "react";
import Diaporama from "./diaporama";

function Photos({ photos }) {
  const [openImage, setOpenImage] = useState(null);

  return (
    <>
      {openImage && (
        <Diaporama
          photos={photos}
          selectedPhoto={openImage}
          onClose={() => setOpenImage(null)}
        />
      )}
      <ul
        style={{
          maxWidth: 768,
          display: openImage ? "none" : "flex",
          flexWrap: "wrap",
          marginTop: "3rem",
          justifyContent: "space-evenly",
        }}
      >
        {photos.map((photo) => (
          <li key={photo}>
            <img
              style={{
                maxHeight: 245,
                objectFit: "cover",
                margin: "4px 0px",
                cursor: "pointer",
              }}
              src={photo}
              alt={photo}
              onClick={() => setOpenImage(photo)}
            />
          </li>
        ))}
      </ul>
    </>
  );
}

export default Photos;
