import { useEffect, useState } from "react";
import axios from "axios";

const useCountries = () => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      const response = await axios.get("/c.json");

      const countries = response.data.documents.sort((c1, c2) =>
        c1.Name > c2.Name ? 1 : -1,
      );
      setCountries(countries);
    };

    fetchCountries();
  }, []);

  return {
    countries,
  };
};

export default useCountries;
