import { useEffect, useState } from "react";
import axios from "axios";

const usePictures = () => {
  const [mainImages, setMainImages] = useState([]);
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      const response = await axios(
        "https://raw.githubusercontent.com/rina-andria/rwt/master/manifest.json",
      );

      setMainImages(response.data.photo);
      setPhotos(response.data.main);
    };

    fetchImages();
  }, []);

  return {
    mainImages,
    photos,
  };
};

export default usePictures;
